// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHomeIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m19.63 11.527-.43-.26v6.954A1.79 1.79 0 0 1 17.4 20h-3.6v-4.22A1.79 1.79 0 0 0 12 14c-.994 0-1.8.797-1.8 1.78V20H6.6a1.79 1.79 0 0 1-1.8-1.78v-6.953l-.43.26a.906.906 0 0 1-1.238-.295.884.884 0 0 1 .299-1.223l8.053-7.01a1 1 0 0 1 1.032 0l8.053 7.01a.884.884 0 0 1 .299 1.223.906.906 0 0 1-1.237.295Z" />
  </Icon>
);
export default SvgHomeIcon;
SvgHomeIcon.displayName = 'SvgHomeIcon';
