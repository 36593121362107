// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDownloadIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m11.22 17.64.06.05h.05l.26.23h.21c.066.006.133.006.2 0a.942.942 0 0 0 .29-.06h.19l.23-.22 5-5a1.004 1.004 0 0 0-1.42-1.42L13 14.59V3a1 1 0 0 0-2 0v11.59l-3.29-3.3a1.004 1.004 0 1 0-1.42 1.42l4.93 4.93Z" />
    <path d="M21 13a1 1 0 0 0-1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-5a1 1 0 1 0-2 0v5a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-5a1 1 0 0 0-1-1Z" />
  </Icon>
);
export default SvgDownloadIcon;
SvgDownloadIcon.displayName = 'SvgDownloadIcon';
