// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgUploadIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M6.07 8.38A.995.995 0 0 1 5.99 8c0-.13.03-.26.08-.38s.12-.23.22-.33l5-5.01.23-.22h.19c.09-.04.19-.06.29-.06H12.41l.26.23h.05l.06.05 4.93 4.93c.19.19.29.44.29.71 0 .27-.11.52-.29.71-.18.19-.44.29-.71.29-.27 0-.52-.11-.71-.29L13 5.33v11.59c0 .27-.11.52-.29.71a.99.99 0 0 1-.71.29c-.27 0-.52-.11-.71-.29a.99.99 0 0 1-.29-.71V5.33L7.71 8.7c-.09.09-.2.17-.33.22-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.22-.17-.2-.22-.33v.01Zm15.63 4.91a.99.99 0 0 0-.71-.29c-.27 0-.52.11-.71.29-.19.18-.29.44-.29.71v5c0 .27-.11.52-.29.71-.18.19-.44.29-.71.29H5c-.27 0-.52-.11-.71-.29A.99.99 0 0 1 4 19v-5c0-.27-.11-.52-.29-.71A.99.99 0 0 0 3 13c-.27 0-.52.11-.71.29A.99.99 0 0 0 2 14v5c0 .8.32 1.56.88 2.12S4.21 22 5 22h14c.8 0 1.56-.32 2.12-.88S22 19.79 22 19v-5c0-.27-.11-.52-.29-.71h-.01Z" />
  </Icon>
);
export default SvgUploadIcon;
SvgUploadIcon.displayName = 'SvgUploadIcon';
