// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgInfoIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm1.433-14.584a1.415 1.415 0 1 0-2.833 0 1.415 1.415 0 1 0 2.833 0ZM10.6 12.248a1.415 1.415 0 1 1 2.83 0v4.17a1.415 1.415 0 1 1-2.83 0v-4.17Z"
    />
  </Icon>
);
export default SvgInfoIcon;
SvgInfoIcon.displayName = 'SvgInfoIcon';
