// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSunIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color || 'iconSolar'} size={size} verticalAlign={verticalAlign}>
    <path d="M13.384 4.038c0-.65-.523-1.167-1.168-1.167-.65 0-1.168.522-1.168 1.167v1.17c0 .65.523 1.167 1.168 1.167.65 0 1.168-.522 1.168-1.167v-1.17ZM12.216 7.899a4.322 4.322 0 0 0-4.317 4.317 4.322 4.322 0 0 0 4.317 4.317 4.322 4.322 0 0 0 4.317-4.317 4.322 4.322 0 0 0-4.317-4.317ZM20.393 11.048h-1.168a1.168 1.168 0 1 0 0 2.336h1.168a1.168 1.168 0 1 0 0-2.336ZM4.039 11.048h1.168a1.168 1.168 0 0 1 0 2.336H4.04a1.168 1.168 0 0 1 0-2.336ZM12.216 18.057c.645 0 1.168.517 1.168 1.167v1.17c0 .645-.518 1.167-1.168 1.167a1.164 1.164 0 0 1-1.168-1.167v-1.17c0-.645.518-1.167 1.168-1.167ZM16.346 16.346a1.164 1.164 0 0 1 1.651-.001l.828.828a1.165 1.165 0 0 1-.001 1.65 1.164 1.164 0 0 1-1.651.002l-.828-.828a1.165 1.165 0 0 1 0-1.651ZM7.26 5.607a1.164 1.164 0 0 0-1.652 0 1.165 1.165 0 0 0 0 1.652l.827.828c.46.46 1.195.455 1.65-.001.46-.46.457-1.195.002-1.651l-.828-.828ZM8.086 16.346c.456.456.46 1.192 0 1.651l-.827.828a1.165 1.165 0 0 1-1.651-.001 1.164 1.164 0 0 1 0-1.651l.827-.828a1.165 1.165 0 0 1 1.65 0ZM18.825 7.26c.46-.46.455-1.196-.001-1.652a1.165 1.165 0 0 0-1.651 0l-.828.827a1.164 1.164 0 0 0 0 1.65c.46.46 1.196.457 1.652.002l.828-.828Z" />
  </Icon>
);
export default SvgSunIcon;
SvgSunIcon.displayName = 'SvgSunIcon';
