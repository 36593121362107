// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgChatIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M2.036 20.84a.909.909 0 0 0 1.011 1.149l5.064-.775a9.97 9.97 0 0 0 3.89.785C17.522 22 22 17.522 22 12 22 6.478 17.523 2 12 2 6.478 2 2 6.477 2 12c0 1.684.419 3.31 1.203 4.756L2.036 20.84ZM5 11.826a1.826 1.826 0 1 0 3.652 0 1.826 1.826 0 0 0-3.652 0Zm7 1.826A1.826 1.826 0 1 1 12 10a1.826 1.826 0 0 1 0 3.652Zm3.348-1.826a1.826 1.826 0 1 0 3.652 0 1.826 1.826 0 0 0-3.652 0Z"
    />
  </Icon>
);
export default SvgChatIcon;
SvgChatIcon.displayName = 'SvgChatIcon';
