// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgDiscountIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M21.41 13 11.29 2.88C10.73 2.32 9.96 2 9.17 2H4c-1.1 0-2 .9-2 2v5.17c0 .8.32 1.56.88 2.12L13 21.41a1.983 1.983 0 0 0 2.82 0l5.59-5.59c.78-.78.78-2.05 0-2.83V13ZM7 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm7.16 9.01c-1.71 0-2.72-1.12-2.92-2.69h-.67v-.66h.63v-.62h-.63v-.66h.67c.21-1.58 1.29-2.73 2.95-2.73 1.66 0 2.58.86 2.74 2.44h-1.51c-.1-.78-.54-1.21-1.24-1.21-.8 0-1.24.59-1.37 1.5h1.86l-.12.66h-1.77v.62h1.66l-.12.66h-1.51c.13.91.56 1.43 1.38 1.43.77 0 1.19-.45 1.31-1.34h1.49c-.19 1.64-1.15 2.6-2.83 2.6Z" />
  </Icon>
);
export default SvgDiscountIcon;
SvgDiscountIcon.displayName = 'SvgDiscountIcon';
