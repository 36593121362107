// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgLogoutIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M15.707 6.293a1 1 0 1 0-1.414 1.414L17.586 11H9a1 1 0 1 0 0 2h8.586l-3.293 3.293a1 1 0 0 0 1.414 1.414l4.93-4.93a1.1 1.1 0 0 0 0-1.555l-4.93-4.93Z" />
    <path fillRule="evenodd" d="M3 2h8.5a1 1 0 1 1 0 2H5v16h6.5a1 1 0 1 1 0 2H3V2Z" clipRule="evenodd" />
  </Icon>
);
export default SvgLogoutIcon;
SvgLogoutIcon.displayName = 'SvgLogoutIcon';
