// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgCloseIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M7.073 5.045a1.432 1.432 0 0 0-2.028 0c-.56.561-.56 1.467 0 2.028L9.973 12l-4.928 4.927c-.56.56-.56 1.467 0 2.028.561.56 1.467.56 2.028 0L12 14.028l4.927 4.927a1.433 1.433 0 1 0 2.028-2.028L14.028 12l4.927-4.927a1.44 1.44 0 0 0 0-2.017 1.432 1.432 0 0 0-2.028 0L12 9.973 7.073 5.045Z"
    />
  </Icon>
);
export default SvgCloseIcon;
SvgCloseIcon.displayName = 'SvgCloseIcon';
