// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgElectricityIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color || 'iconElectricity'} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M15.317 3h-3.28a2 2 0 0 0-1.87 1.292L7.513 11.3a1 1 0 0 0 .935 1.354h1.807a.3.3 0 0 1 .288.385l-2.576 8.747c-.09.303.29.52.507.29l8.53-9.118c.598-.639.145-1.683-.73-1.683h-2.14a.3.3 0 0 1-.282-.404l2.403-6.526A1 1 0 0 0 15.317 3Z"
    />
  </Icon>
);
export default SvgElectricityIcon;
SvgElectricityIcon.displayName = 'SvgElectricityIcon';
