// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHamburgerMenuIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M4.28 8.5h16a1.25 1.25 0 0 0 0-2.5h-16a1.25 1.25 0 0 0 0 2.5ZM20.28 11.03h-16a1.25 1.25 0 0 0 0 2.5h16a1.25 1.25 0 0 0 0-2.5ZM20.28 16h-16a1.25 1.25 0 0 0 0 2.5h16a1.25 1.25 0 0 0 0-2.5Z" />
  </Icon>
);
export default SvgHamburgerMenuIcon;
SvgHamburgerMenuIcon.displayName = 'SvgHamburgerMenuIcon';
