// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgMinusIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path fillRule="evenodd" d="M10.611 11h10a1.389 1.389 0 1 1 0 2.778H3.39a1.389 1.389 0 1 1 0-2.778h7.222Z" />
  </Icon>
);
export default SvgMinusIcon;
SvgMinusIcon.displayName = 'SvgMinusIcon';
