// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgMobileIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M7.6 4.8a.8.8 0 0 1 .8-.8h7.2a.8.8 0 0 1 .8.8v14.4a.8.8 0 0 1-.8.8H8.4a.8.8 0 0 1-.8-.8V4.8ZM8.4 2a2.8 2.8 0 0 0-2.8 2.8v14.4A2.8 2.8 0 0 0 8.4 22h7.2a2.8 2.8 0 0 0 2.8-2.8V4.8A2.8 2.8 0 0 0 15.6 2H8.4Zm2.555 14.4a1 1 0 1 0 0 2h2.09a1 1 0 1 0 0-2h-2.09Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgMobileIcon;
SvgMobileIcon.displayName = 'SvgMobileIcon';
