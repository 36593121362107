// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSendIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="m20.8 10.17-16-7A2 2 0 0 0 2 5.33l.8 4.83a1 1 0 0 0 1 .84H13a1 1 0 0 1 0 2H3.82a1 1 0 0 0-1 .84L2 18.67A2 2 0 0 0 4 21c.275-.002.548-.06.8-.17l16-7a2 2 0 0 0 0-3.66Z" />
  </Icon>
);
export default SvgSendIcon;
SvgSendIcon.displayName = 'SvgSendIcon';
