// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHelpIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm2.943 4.482c-.706-.655-1.676-.982-2.913-.982-.916 0-1.701.179-2.357.536-.655.357-1.156.797-1.503 1.321-.26.381-.222.685.112.91l.853.572c.322.226.588.197.798-.089.557-.738 1.194-1.107 1.911-1.107.482 0 .866.13 1.15.393.297.262.446.583.446.964 0 .333-.13.696-.39 1.09-.247.38-.52.726-.816 1.035-.297.298-.575.66-.835 1.09-.248.428-.371.839-.371 1.231v.393c0 .107.037.197.111.268.074.072.16.107.26.107h1.633c.098 0 .185-.03.26-.089a.356.356 0 0 0 .11-.268v-.125c0-.297.137-.63.409-1 .272-.38.569-.732.89-1.053a5.6 5.6 0 0 0 .89-1.268c.273-.524.409-1.06.409-1.607 0-.905-.352-1.679-1.057-2.322Zm-3.878 11.554c.321.31.711.464 1.169.464.445 0 .828-.155 1.15-.464.322-.31.482-.679.482-1.107 0-.44-.16-.816-.482-1.125a1.601 1.601 0 0 0-1.15-.465c-.458 0-.848.155-1.17.465-.32.31-.482.684-.482 1.125 0 .428.161.797.483 1.107Z"
    />
  </Icon>
);
export default SvgHelpIcon;
SvgHelpIcon.displayName = 'SvgHelpIcon';
