// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgHeartIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M15.56 3c-1.29 0-2.52.37-3.56 1.06A6.39 6.39 0 0 0 8.44 3C4.89 3 2 5.86 2 9.36c0 1.67.66 3.25 1.87 4.48l6.73 6.56c.39.38.89.57 1.4.57.51 0 1.01-.19 1.4-.57l6.72-6.54.02-.02a6.263 6.263 0 0 0 1.87-4.48c0-3.51-2.89-6.36-6.44-6.36h-.01Z" />
  </Icon>
);
export default SvgHeartIcon;
SvgHeartIcon.displayName = 'SvgHeartIcon';
