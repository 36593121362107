// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSuccessIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M2 12C2 6.47 6.47 2 12 2s10 4.47 10 10-4.47 10-10 10S2 17.53 2 12Zm15.47-2.712a1.25 1.25 0 0 0-1.94-1.576l-5.51 6.782-1.535-1.963a1.25 1.25 0 0 0-1.97 1.539l1.727 2.21a2.25 2.25 0 0 0 3.52.034l5.708-7.026Z"
    />
  </Icon>
);
export default SvgSuccessIcon;
SvgSuccessIcon.displayName = 'SvgSuccessIcon';
