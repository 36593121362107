// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgVisibilityOffIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M12 4c2.317 0 4.215.503 5.742 1.267l-3.155 2.454a5 5 0 0 0-7.372 5.734l-3.94 3.064C1.686 14.827 1 12.956 1 12c0-2 3-8 11-8Z" />
    <path
      fillRule="evenodd"
      d="m20.978 7.762 1.636-1.273a1 1 0 0 0-1.228-1.578l-18 14a1 1 0 1 0 1.228 1.578l2.025-1.575C8.097 19.575 9.872 20 12 20c8 0 11-6 11-8 0-.904-.612-2.624-2.022-4.238Zm-4.09 3.18-1.92 1.494a3 3 0 0 1-3.276 2.548l-1.92 1.493a5 5 0 0 0 7.116-5.535Z"
      clipRule="evenodd"
    />
    <path d="M12 9c.278 0 .547.038.803.109L9 12.066 9 12a3 3 0 0 1 3-3Z" />
  </Icon>
);
export default SvgVisibilityOffIcon;
SvgVisibilityOffIcon.displayName = 'SvgVisibilityOffIcon';
