// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgEnvelopeIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm.275 3.489 6.408 6a1.954 1.954 0 0 0 2.634 0l6.408-6c.587-.55.171-1.489-.658-1.489H4.933c-.83 0-1.245.94-.658 1.489Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SvgEnvelopeIcon;
SvgEnvelopeIcon.displayName = 'SvgEnvelopeIcon';
