// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgCheckIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M18.616 6.246a1.051 1.051 0 0 0-1.14-.138 1.08 1.08 0 0 0-.375.3l-7.254 9.091-.455.559-.445-.568-2.017-2.633a1.06 1.06 0 0 0-.853-.426 1.089 1.089 0 0 0-1.07.976c-.033.29.048.582.227.813l2.273 2.965a2.367 2.367 0 0 0 3.741 0l7.52-9.414a1.117 1.117 0 0 0-.152-1.525Z"
    />
  </Icon>
);
export default SvgCheckIcon;
SvgCheckIcon.displayName = 'SvgCheckIcon';
