// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgGasIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color || 'iconGas'} size={size} verticalAlign={verticalAlign}>
    <path
      fillRule="evenodd"
      d="M17.41 8.152a7.378 7.378 0 0 1 1.74 2.447 7.38 7.38 0 0 1 .628 2.992 7.383 7.383 0 0 1-2.166 5.243A7.35 7.35 0 0 1 12.389 21a7.37 7.37 0 0 1-5.223-2.168A7.384 7.384 0 0 1 5 13.589c0-1.137.26-2.259.76-3.277a7.47 7.47 0 0 1 2.105-2.58 6.24 6.24 0 0 0 .997-.98 6.074 6.074 0 0 0 .987-1.73c.189-.512.269-1.128.237-1.824l-.046-1.02c-.006-.133.139-.223.252-.155l.867.516c1.188.71 2.139 1.727 2.823 3.03.827 1.57.596 4.653.518 5.931a.125.125 0 0 0 .042.102c.031.03.067.034.09.032a.13.13 0 0 0 .086-.043L16 10l.526-2.345a.172.172 0 0 1 .118-.108.168.168 0 0 1 .155.038l.611.567Z"
    />
  </Icon>
);
export default SvgGasIcon;
SvgGasIcon.displayName = 'SvgGasIcon';
