// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgCompareBarsIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M10 17v4H2v-4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2Zm4-8h-4c-1.1 0-2 .9-2 2v2h2c1.1 0 2 .9 2 2v6h4V11c0-1.1-.9-2-2-2Zm6-6h-4c-1.1 0-2 .9-2 2v2h2c1.1 0 2 .9 2 2v12h4V5c0-1.1-.9-2-2-2Z" />
  </Icon>
);
export default SvgCompareBarsIcon;
SvgCompareBarsIcon.displayName = 'SvgCompareBarsIcon';
